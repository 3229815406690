import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import About from "./pages/About"
import Main from "./pages/Main";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
import Help from "./pages/Help";
import Team from "./pages/Team";
import Banana from "./portPages/banana/Banana";
import Work5 from "./portPages/work5/Work5";
import Work2 from "./portPages/work2/Work2";
import Work3 from "./portPages/work3/Work3";
import Work4 from "./portPages/work4/Work4";
import CustomCursor from "./components/CustomCursor/CustomCursor";
import Politica from "./pages/politica/Politica";
import MainFirst from './pages/MainFirst';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/main" element={<Main/>} />
                <Route path='/about' element={<About/>} />
                <Route path='/services' element={<Services/>}/>
                <Route path='/portfolio' element={<Portfolio/>}/>
                <Route path='/help' element={<Help/>}/>
                <Route path='/team' element={<Team/>}/>
                <Route path='/politic' element={<Politica/>}/>
                <Route path='/' element={<MainFirst/>}/>

                <Route path='/work1' element={<Banana/>}/>
                <Route path='/work2' element={<Work2/>}/>
                <Route path='/work3' element={<Work3/>}/>
                <Route path='/work4' element={<Work4/>}/>
                <Route path='/work5' element={<Work5/>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
